$primary: #1f95d9;
$secondary: #909090;

@import "node_modules/bootstrap/scss/bootstrap";

body {
  background: #eeeeee;
}

.tooltip {
  z-index: 1151 !important;
}